.Tank {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    text-decoration: none;
    color: currentColor;

    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(2, 1fr);
    }

    &:hover {

        .Tank-image,
        .Tank-placeholder {
            opacity: .9;
        }
    }

    &-image {
        position: relative;
        background-color: $cc-grey--100;
        transition: opacity .6s ease;

        picture {
            height: 100%;
            &:before {
                content: "";
            }
        }
        .Tank-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            &:hover,
            &:focus {
                + .Tank-icon {
                    svg {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 4.8rem;
        width: 4.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $cc-grey--400;
        background-color: $cc-grey--700;

        svg {
            height: 3.2rem;
            width: 3.2rem;
            transform: rotate(45deg);
            fill: currentColor;
            transition: transform .15s ease .05s;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $cc-beige--400;
        padding: 2.5rem;
        @include mq($from: $viewport--md) {
            padding: 5rem 2.5rem;
        }
        @include mq($from: $viewport--lg) {
            padding: 10rem 5rem 10rem 7.5rem;
        }
        @include mq($from: 1600) {
            padding: 10rem 5rem 10rem 15rem;
        }
    }

    &-title {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &-link {

    }
}

.Block.Block--tank {
    padding-bottom: 0;
    &:first-child {
        padding-top: rem($structure-header-height--mobile) !important;
        @include mq($from: $viewport--lg) {
            padding-top: rem($structure-header-height) !important;
        }
    }
}
