.Nav--footer {

    @include mq($from: $viewport--md) {

        & + & {
            margin-top: 1.6rem;
        }
    }

    .Nav-list {
        flex-direction: column;
    }

    .Nav-link {

        &,
        &:active,
        &:focus {
            color: currentColor;
        }

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: .1rem;
        }
    }
}
