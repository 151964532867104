/*
    Image Row
 */

.ImageRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 1rem;

    @include mq($viewport--sm) {
        width: 75%;
    }

    > .ImageRow-link,
    > .ImageRow-image {
        &:first-child {
            grid-column: 1 / 4;
            grid-row: 1 / 4;
        }
    }
}
