.LinkList {
    list-style-type: none;
    margin: -.25rem -3rem;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-item {
        margin: .25rem 3rem;
    }
}
