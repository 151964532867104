/* Heading */
h1, .#{$namespace}Alfa,
h2, .#{$namespace}Bravo,
h3, .#{$namespace}Charlie,
h4, .#{$namespace}Delta,
h5, .#{$namespace}Echo,
h6, .#{$namespace}Foxtrot {
    font-family: $font-degular;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color: var(--page-text-color, #{$heading-color});
    word-break: break-word;

    small {
        font-weight: normal;
    }
    strong {
        font-weight: 500;
    }
}

h1, .#{$namespace}Alfa {
    font-size: clamp(#{u(32)}, 5vw, #{u($heading-size-1)});
    line-height: 1.125;
}

h2, .#{$namespace}Bravo {
    font-size: clamp(#{u(28)}, 5vw, #{u($heading-size-2)});
    line-height: 1.2;
}

h3, .#{$namespace}Charlie {
    font-size: clamp(#{u(24)}, 5vw, #{u($heading-size-3)});
    line-height: 1.2;
}

h4, .#{$namespace}Delta {
    font-size: clamp(#{u(20)}, 5vw, #{u($heading-size-4)});
    line-height: 1.2;
}

h5, .#{$namespace}Echo {
    font-size: u($heading-size-5);
    line-height: 1.2;
}

h6, .#{$namespace}Foxtrot {
    font-size: u($heading-size-6);
    line-height: 1.2;
}


// A series of classes for setting massive type; for use in heroes, mastheads,
// promos, etc.

.#{$namespace}Giga {
    @include font-size($heading-size-giga, $heading-spacing-giga);
}

.#{$namespace}Mega {
    @include font-size($heading-size-mega, $heading-spacing-mega);
}

.#{$namespace}Kilo {
    @include font-size($heading-size-kilo, $heading-spacing-kilo);
}

.#{$namespace}Milli {
    @include font-size($heading-size-milli, $heading-spacing-milli);
}

.#{$namespace}Micro {
    @include font-size($heading-size-micro, $heading-spacing-micro);
}
