/* Link */
a {
    @include transition(#{background-color, color, opacity});
    color: var(--page-link-color, #{$link-color});
    text-decoration: $link-text-decoration;
    text-decoration-thickness: .1rem;
    text-underline-offset: .15em;

    &:hover,
    &:focus,
    &:active {
        color: var(--page-link-hover-color, #{$link-color--hover});
        text-decoration: $link-text-decoration--hover;
    }

    &:focus-visible {
        outline: 0;
        box-shadow: $button-focus-shadow;
    }
}
