.BlockArticle {
    @include mq($from: 980) {
        padding: 0 10rem;
    }
    &-body {
        display: flex;
        flex-direction: column;
    }
    &-title {
        margin-bottom: clamp(4rem, 5vw, 8rem);
        &:last-child {
            margin-bottom: 0;
        }
        @include mq($viewport--sm) {
            width: 75%;
        }
    }
    &-text {
        align-self: flex-end;
        margin-bottom: clamp(4rem, 5vw, 8rem);
        &:last-child {
            margin-bottom: 0;
        }
        @include mq($from: $viewport--md) {
            width: 80%;
        }
        @include mq($from: $viewport--xl) {
            width: 100%;
            max-width: 80rem;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        + .BlockArticle-links {
            align-self: flex-end;
        }
    }
    &-links {
        overflow: hidden;

        @include mq($from: $viewport--md) {
            width: 80%;
        }
        @include mq($from: $viewport--xl) {
            width: 100%;
            max-width: 80rem;
        }
    }
    &--center {
        .BlockArticle-title,
        .BlockArticle-text,
        .BlockArticle-links {
            align-self: center;
        }
    }
}
