/* Functions: Units */

// Strip unit
@function strip-unit($value) {
    @if not is-number($value) and not is-measure($value) {
        @return throw('`#{$value}` is not a value for `strip-unit()`');
    }

    @return $value / ($value * 0 + 1);
}

// Add unit
@function add-unit($number, $unit) {
    @if not is-number($number) {
        @return throw('`#{$number}` is not a number for `add-unit()`');
    }

    @if $unit == 'px' {
        @return $number * 1px;
    } @else if $unit == 'cm' {
        @return $number * 1cm;
    } @else if $unit == 'mm' {
        @return $number * 1mm;
    } @else if $unit == '%' {
        @return $number * 1%;
    } @else if $unit == 'ch' {
        @return $number * 1ch;
    } @else if $unit == 'pica' {
        @return $number * 1pica;
    } @else if $unit == 'in' {
        @return $number * 1in;
    } @else if $unit == 'em' {
        @return $number * 1em;
    } @else if $unit == 'rem' {
        @return $number * 1rem;
    } @else if $unit == 'pt' {
        @return $number * 1pt;
    } @else if $unit == 'pc' {
        @return $number * 1pc;
    } @else if $unit == 'ex' {
        @return $number * 1ex;
    } @else if $unit == 'vw' {
        @return $number * 1vw;
    } @else if $unit == 'vh' {
        @return $number * 1vh;
    } @else if $unit == 'vmin' {
        @return $number * 1vmin;
    } @else if $unit == 'vmax' {
        @return $number * 1vmax;
    } @else {
        @return false;
    }
}

// Replace unit
@function replace-unit($measure, $unit) {
    $measure: strip-unit($measure);

    @return add-unit($measure, $unit);
}

// EM
@function em($values...) {
    $_return: ();
    $_result: null;

    @each $value in $values {
        @if not is-number($value) and not is-measure($value) and $value != 'auto' {
            @return throw('`#{$value}` is not a value for `em()`');
        }

        @if $value == 'auto' or unit($value) == 'em' {
            $_result: $value;
        } @else if unit($value) == 'rem' {
            $_result: replace-unit($value, em);
        } @else {
            $_strip: strip-unit($value);
            $_result: $_strip / $font-size;
            $_result: add-unit($_result, em);
        }

        $_return: append($_return, $_result);
    }

    @return $_return;
}

// REM
@function rem($values...) {
    $_return: ();
    $_result: null;

    @each $value in $values {
        @if not is-number($value) and not is-measure($value) and $value != 'auto' {
            @return throw('`#{$value}` is not a value for `rem()`');
        }

        @if $value == 'auto' or unit($value) == 'rem' {
            $_result: $value;
        } @else if unit($value) == 'em' {
            $_result: replace-unit($value, rem);
        } @else {
            $_strip: strip-unit($value);
            $_result: $_strip / 10;
            $_result: add-unit($_result, rem);
        }

        $_return: append($_return, $_result);
    }

    @return $_return;
}

// PX
@function px($values...) {
    $_return: ();
    $_result: null;

    @each $value in $values {
        @if not is-number($value) and not is-measure($value) and $value != 'auto' {
            @return throw('`#{$value}` is not a value for `px()`');
        }

        @if $value == 'auto' or unit($value) == 'px' {
            $_result: $value;
        } @else if unit($value) == 'em' or unit($value) == 'rem' {
            $_strip: strip-unit($value);
            $_result: $_strip * $font-size;
            $_result: add-unit($_result, px);
        } @else {
            $_result: replace-unit($value, px);
        }

        $_return: append($_return, $_result);
    }

    @return $_return;
}

// Default unit
@function default-unit($values...) {
    @if not is-unit($unit) {
        @return throw('`#{$unit}` is not an unit for `unit()`');
    }

    @if $unit == 'em' {
        @return em($values...);
    } @else if $unit == 'rem' {
        @return rem($values...);
    }

    @return px($values...);
}

@function u($values...) {
    @return default-unit($values...);
}