/* Fields: file */
.#{$form-namespace}Form-field--file {
    cursor: pointer;
    display: block;

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        // UI state
        &:focus ~ .#{$form-namespace}Form-indicator {
            &:before {
                background-color: $white;
            }

            /*border-color: $form-element-border-color--focus;
            box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-element-box-shadow--focus);

            // Error
            .#{$form-namespace}Form-item.is-error & {
                box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-error-element-box-shadow);
            }

            // Success
            .#{$form-namespace}Form-item.is-success & {
                box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-success-element-box-shadow);
            }*/
        }

        &:hover ~ .#{$form-namespace}Form-indicator {
            &:after {
                background-color: rgba($white, .75);
            }
        }
    }

    .#{$form-namespace}Form-indicator {
        @include font-smoothing($font-smoothing);
        background-color: var(--page-input-background-color, #{$form-element-background-color});
        border-radius: if($form-element-border-radius > 0, px($form-element-border-radius), 0);
        border-bottom: px($form-element-border-width) solid $form-element-border-color;
        box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow);
        color: $form-element-color;
        display: block;
        height: $form-element-height;
        line-height: normal;
        overflow: hidden;

        position: relative;
        white-space: nowrap;
        width: 100%;

        &::before {
            @extend .u-textTruncate !optional;
            content: attr(data-file-text);
            padding: rem($form-element-padding--t) 10rem rem($form-element-padding--b) rem($form-element-padding--l);
            font-size: 1.6rem;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
        }

        &::after {
            background-color: $white;
            color: $form-file-element-button-color;
            content: attr(data-file-button);
            padding: rem($form-element-padding--t, $form-element-padding--r, $form-element-padding--b, $form-element-padding--l);
            text-align: center;
            width: u($form-file-element-button-width);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;
            transition: .3s background-color ease;
        }
    }


    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}
