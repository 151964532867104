/* Footer */
.Footer {
    background-color: $cc-beige--100;
    color: $cc-grey--700;
    padding-top: 3.2rem;
    font-size: 1.6rem;
    padding-bottom: 1.6rem;
    //letter-spacing: 0.03em;

    a:not([class]) {
        color: currentColor;
        text-decoration: none;

        &,
        &:active,
        &:focus {
            color: currentColor;
        }

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: .1rem;
        }
    }
}

// Item
.Footer-item--withBorder {
    border-top: 1px solid;
    margin-top: 3.2rem;
    padding-top: 2.4rem;

    @include mq($from: $viewport--lg) {
        margin-top: 2.4rem;
        padding-top: 3.2rem;
    }

    @include mq($until: $viewport--lg - 1) {
        border-bottom: 1px solid;
        margin-bottom: 2.4rem;
        padding-bottom: 2.4rem;
    }
}

.Footer-item--legal {

    @include mq($from: $viewport--lg) {
        margin-top: 6.4rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    p {
        @include mq($from: $viewport--lg) {
            font-size: 2.8rem;
            margin-bottom: 0;
        }
    }
}

// Columns
.Footer-columns {
    line-height: (1 / 16 * 29);

    @include mq($from: $viewport--lg) {
        justify-content: end;
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        justify-content: space-between;
    }

    @include mq($from: $viewport--md) {
        --gutter: 2.4rem;
        display: flex;
        margin-left: calc(var(--gutter) * -1);
    }

    > .Footer-column {

        :last-child {
            margin-bottom: 0;
        }

        @include mq($from: $viewport--xl) {
            --columnCount: 6;
        }

        @include mq($from: $viewport--lg, $until: $viewport--xl) {
            --columnCount: 5;
        }

        @include mq($from: $viewport--lg) {
            width: calc(100% / var(--columnCount));
        }

        @include mq($from: $viewport--md) {
            padding-left: var(--gutter);
        }

        @include mq($until: $viewport--md - 1) {

            &:not(:last-child) {
                border-bottom: 1px solid;
                margin-bottom: 2.4rem;
                padding-bottom: 2.4rem;
            }

            &.has-multiple {
                --gutter: 2.4rem;
                display: flex;
                margin-left: calc(var(--gutter) * -1);

                > .Nav {
                    margin-left: var(--gutter);
                    width: 50%;
                }
            }
        }
    }
}
