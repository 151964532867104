.BlockLinks {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 2.5rem;

    @include mq($from: $viewport--sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    .BlockLink {
        display: flex;
        flex-direction: column;

        .BlockLink-body {
            flex-grow: 1;
        }

        &:nth-child(even) {
            &:before {
                @include mq($from: $viewport--md) {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -1.25rem;
                    height: 100%;
                    width: 1px;
                    margin-left: -.5px;
                    background-color: var(--page-border-color, #{$cc-beige--400});
                    opacity: .5;
                }
            }
        }
    }
}

.BlockLink {
    position: relative;
    display: block;
    text-decoration: none;
    color: var(--page-text-color);

    &-image,
    &-placeholder {
        position: relative;
        background-color: $cc-grey--100;
    }

    &-image {
        img {
            transition: .45s opacity ease, .3s transform ease;
        }
    }

    &-placeholder {
        transition: opacity .6s ease;

        // Ratios
        @each $ratio-name, $ratio-value in $RATIOS {
            @each $antecedent, $consequent in $ratio-value {
                @if (type-of($antecedent) != number) {
                    @error "`#{$antecedent}` needs to be a number.";
                }
                @if (type-of($consequent) != number) {
                    @error "`#{$consequent}` needs to be a number.";
                }
                &[data-ratio="#{$antecedent}:#{$consequent}"] {
                    padding-bottom: ($consequent/$antecedent) * 100%;
                }
            }
        }
    }
    &-body {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 2.5rem 3rem;
    }
    &-label {
        flex-grow: 1;
    }
    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--page-text-color);
        margin-left: 1rem;

        svg {
            height: 3.2rem;
            width: 3.2rem;
            transform: rotate(45deg);
            fill: currentColor;
            transition: transform .2s ease;
        }
    }
}

a.BlockLink {

    &:hover,
    &:focus {
        color: var(--page-text-color);

        .BlockLink-image {
            img {
                opacity: .9;
                transform: scale(1.1);
            }
        }
        .BlockLink-placeholder {
            opacity: .9;
        }
        .BlockLink-icon {
            svg {
                transform: rotate(-45deg);
            }
        }
    }
}
