/* Captions */

.Captions {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 2rem;
    font-size: 2.4rem;
    line-height: 1.5;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    &-item {
        margin-bottom: 0;
    }

    &-numbers {
        display: flex;
        align-items: center;
        span {
            &:nth-child(2) {
                position: relative;
                padding-left: 3rem;
                &:before {
                    content: "";
                    position: absolute;
                    top: calc(50% + 1px);
                    left: .75rem;
                    height: 2px;
                    width: 1.5rem;
                    background-color: var(--page-border-color, #{$cc-beige--400});
                    opacity: .5;
                }
            }
        }
    }

    &-text {

    }
}
