/* Slider: navigation */
.Slider-button {
    @include dimensions(27, 44);
    @include position(absolute, $top: 50%, $z: 10);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 27px 44px;
    color: $primary-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: u(-22);

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
        pointer-events: none;
    }

    &:after {
        font-family: Slider-icons;
        font-size: u(44);
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    right: auto;
    left: u(10);

    &:after {
        content: 'prev';
    }
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    right: u(10);
    left: auto;

    &:after {
        content: 'next';
    }
}
