.Article {
    padding: 5rem 0;

    @include mq($from: $viewport--md) {
        padding: 10rem 0;
    }
    @include mq($from: $viewport--xl) {
        padding: 15rem 0;
    }

    &-wrapper {

    }

    &-header {
        margin-bottom: 10rem;
    }

    &-image {

    }

    &-placeholder {
        background-color: $cc-grey--100;
    }

    &-content {

    }

    &-contentMain {
        @include mq($from: $viewport--md) {
            margin: 0 auto;
            width: 80%;
        }
        @include mq($from: $viewport--xl) {
            width: 100%;
            max-width: 80rem;
        }
    }

    &-contentLongForm {
        .Block {
            padding: 0;
            &--text {

            }
            &--quote,
            &--image,
            &--gallery {
                margin: 6rem 0;
                @include mq($from: $viewport--md) {
                    margin: 9rem 0;
                }
                @include mq($from: $viewport--lg) {
                    margin: 12rem 0;
                }
            }
        }
    }

    &-caption {
        font-size: 2.4rem;
        line-height: 1.5;
        margin: .5rem 0 8rem;

        span {
            &:first-child {
                margin-right: 2.5rem;
            }
        }
    }

    &-data {
        display: grid;
        grid-template-rows: auto;
        grid-gap: 2.5rem;
        font-size: 2.4rem;
        line-height: 1.5;
        margin-bottom: 4rem;
        @include mq($from: $viewport--sm) {
            grid-template-columns: repeat(3, 1fr);
        }
        &Item {
            h4 {
                margin-bottom: .25rem;
            }
        }
    }

    &-intro {
        margin-bottom: 4.5rem;
    }

    &-nav {

    }
}
