.Nav--tertiary {

    .Header & {

        // Item
        .Nav-item {

            + .Nav-item {
                margin-top: 0.6rem;
            }
        }
    }

    // List
    .Nav-list {
        flex-direction: column;
    }

    // Link
    .Nav-link {

        &,
        &:active,
        &:focus {
            color: currentColor;
        }
    }
}
