.abs-Teaser {
    display: grid;
    row-gap: 4rem;
    //font-size: clamp(2.2rem, 5vw, 2.8rem);

    @include mq($from: $viewport--lg) {
        grid-template-columns: 1fr 1fr 50%;
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        column-gap: 1.2rem;
        grid-template-columns: 1fr 1fr;
    }

    @include mq($from: $viewport--md) {
        column-gap: 3.2rem;
        row-gap: 3.2rem;
    }
}

.abs-Teaser-title {
    margin-bottom: 0;
}

.abs-Teaser-object {

    @include mq($from: $viewport--md) {
        grid-column: 1 / -1;
    }
}

.abs-Teaser-title {

    @include mq($from: $viewport--md) {
        font-size: inherit;
    }

    @include mq($from: $viewport--lg) {
        grid-column: 1 / 3;
    }
}

.abs-Teaser-body {

    @include mq($from: $viewport--lg) {
        grid-column: 3 / 4;
    }
}

.abs-Teaser-links {
    overflow: hidden;

    @include mq($until: $viewport--md - 1) {
        margin-top: 4rem;
    }
}

.Block--teaser {
    .abs-Teaser {
        @include mq($from: $viewport--lg) {
            grid-template-columns: 1fr 1fr 60rem;
        }
        @include mq($from: $viewport--xl) {
            // 90rem = 80rem (text width) + 10rem (padding)
            grid-template-columns: 1fr 1fr 90rem;
        }
    }
    .abs-Teaser-title {
        @include mq($from: 980) {
            padding-left: 10rem;
        }
    }
    .abs-Teaser-body {
        @include mq($from: 980) {
            padding-right: 10rem;
        }
    }
}
