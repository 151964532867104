/* Icon */
.Icon {
    fill: currentColor;
    display: block;
    overflow: visible;

    .Button &,
    .Link & {
        flex-shrink: 0;
    }
}
