.Project {
    @include transition(opacity);
    display: grid;

    &:not(.Project--info):not(.Project--quote) {
        grid-template-rows: 3.8rem 1fr;
    }

    &.has-link:hover img {
        opacity: .9;
        transform: scale(1.1);
    }

    .abs-Grid-cell &.has-img {
        margin-top: auto;
    }

    .abs-Grid-cell--large &.has-img {
        height: 100%;
    }

    .abs-Grid-cell--span & {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.4rem;
        align-items: start;
    }

    .abs-Grid-cell--projectImage2 & {
        @include mq($until: $viewport--md - 1) {
            grid-template-rows: auto !important;
        }
    }
}

.Project-title {
    //font-size: clamp(2rem, 4vw, 2.4rem);
    font-size: 2.4rem;

    .abs-Grid--withCounter &::before {
        content: counter(i, decimal-leading-zero);
    }

    &:last-child {
        margin-bottom: 0;
    }

    .Project:not(.Project--info):not(.Project--quote) & {
        @include truncate(1);
        height: 3rem;
        margin-bottom: 0.8rem;

        &::before {
            margin-right: 2.4rem;
        }
    }

    .Project--quote & {
        display: block;
        margin-bottom: 0.8rem;
        font-size: 2.8rem;

        &::before {
            display: block;
            font-size: clamp(2rem, 4vw, 2.4rem);
            margin-bottom: 0.8rem;
        }
    }

    .abs-Grid-cell--projectInfo & {
        opacity: 0;
        @include mq($until: $viewport--md - 1) {
            display: none;
        }
    }
}

.Project-text {
    font-size: 2.2rem;
}

.Project-body {
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    .Project:not(.Project--info):not(.Project--quote) & {
        height: 3.8rem;
    }

    .abs-Grid-cell--span & {
        grid-column: 1 / 3;
    }

    .abs-Grid-cell--projectImage2 & {
        @include mq($until: $viewport--md - 1) {
            display: none;
            height: 0 !important;
        }
    }
}

.Project-suffix {
    font-size: clamp(2rem, 4vw, 2.4rem);
}

.Project-object,
.Project-link {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.Project-link {
    @include position(relative, $z: 1);
    //height: 100%;

    .Block--projects & {
        grid-row: 1 / 3;
    }
}

.Project-object {
    color: $cc-grey--700;
    &:only-child {
        grid-row: 1 / 3;
    }
    img {
        transition: .45s opacity ease, .3s transform ease;
    }
}

.Project-teaser {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 2.8rem;
    margin-bottom: 1rem;
}

.Project-location {
    display: block;
    font-size: 2.2rem;
    opacity: .7;
}

.Project-labels {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.Project-label {
    padding: .5rem 1.5rem;
    font-size: 1.6rem;
    background-color: $cc-beige--400;
    border-radius: 100px;
    color: $cc-grey--700;
    pointer-events: none;
}
