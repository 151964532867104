.BlockForm {
    position: relative;
    margin: 0 auto;
    @include mq($from: $viewport--md) {
        width: 75%;
    }
    @include mq($from: $viewport--xl) {
        width: 50%;
    }

    &-title {
        margin-bottom: clamp(4rem, 5vw, 8rem);
    }
    &-item {
        margin-bottom: clamp(4rem, 5vw, 8rem);
        @include mq($from: $viewport--md) {
            margin-bottom: 0;
        }
    }
    &-data {
        font-size: 2.4rem;
        p {
            @include mq($from: $viewport--md) {
                margin-bottom: 5rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            color: currentColor;
            text-decoration: none;
        }
    }

    &--grid {
        @include mq($from: $viewport--md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-template-areas:
                "title ."
                "item data";
            grid-gap: 0 5rem;
            width: 100%;
        }
        @include mq($from: 840) {
            grid-template-columns: 2fr 1fr;
            grid-gap: 0 10rem;
        }
        @include mq($from: 980) {
            padding: 0 10rem;
        }
        @include mq($from: $viewport--xl) {
            grid-template-columns: minmax(auto, 80rem) 1fr;
            grid-gap: 0 20rem;
        }

        .BlockForm-title {
            @include mq($from: $viewport--md) {
                grid-area: title;
            }
        }
        .BlockForm-item {
            @include mq($from: $viewport--md) {
                grid-area: item;
            }
        }
        .BlockForm-data {
            @include mq($from: $viewport--md) {
                grid-area: data;
            }
        }
    }

    &--align {
        &Left {
            @include mq($from: $viewport--lg) {
                margin-left: 10rem;
            }
            @include mq($from: $viewport--xl) {
                margin-left: 15rem;
            }
        }
        &Right {
            @include mq($from: $viewport--lg) {
                margin-right: 10rem;
            }
            @include mq($from: $viewport--xl) {
                margin-right: 15rem;
            }
        }
    }
}
