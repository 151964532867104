.HeroSlider {
    position: relative;
    pointer-events: none;

    * {
        pointer-events: none;
    }

    @include mq($from: 980) {
        height: 100vh;
    }

    @include mq($from: 1600) {
        max-height: none;
    }

    @media screen and (min-width: 61.25em) and (min-height: 100em) {
        max-height: 120rem;
    }

    &-items,
    &-item  {
        height: 100%;
    }

    &-items {
        &.Slider-items {
            box-sizing: border-box;
        }
    }

    &-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        picture {
            height: 100%;
            &::before {
                content: none;
            }
        }

        img {
            pointer-events: none;
        }
    }

    &-cover {
        margin: 0 auto;
        height: 100%;
        width: 100%;
        position: relative;


        @include mq($from: $viewport--sm) {
            padding: 10rem 2rem 8rem;
        }

        @include mq($from: $viewport--md) {
            padding: 20rem 0 10rem;
        }

        &Item {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        picture {
            width: 100%;
            @include mq($from: $viewport--sm) {
                height: 100%;
                width: auto;
            }
            &::before {
                @include mq($from: $viewport--sm) {
                    content: none;
                }
            }
        }

        img {
            @include mq($from: $viewport--sm) {
                position: static !important;
                height: 100%;
                width: auto !important;
            }
        }
    }

    &-icon {
        display: none;
        position: absolute;
        bottom: 1.75rem;
        right: 50%;
        z-index: 1;
        margin-left: -3rem;
        height: 6rem;
        width: 6rem;
        justify-content: center;
        align-items: center;
        color: $white;
        transition: opacity .1s ease, transform .25s ease;
        animation-name: pulse;
        animation-duration: 2s;
        animation-delay: 1.5s;
        animation-iteration-count: infinite;

        @include mq($from: 980) {
            display: flex;
        }

        svg {
            height: 3.2rem;
            width: 3.2rem;
            transform: rotate(90deg);
            fill: currentColor;
        }
    }
}

@keyframes pulse {
    0%,
    20%,
    80%,
    100% {
        transform: translateY(0);
        opacity: 1;
    }
    50% {
        transform: translateY(1rem);
        opacity: .85;
    }
}
