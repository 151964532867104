.SideNavigationToggle {
    position: absolute;
    top: -.6rem;
    right: 0;
    z-index: 5;
    height: 4rem;
    width: 4rem;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: $white;
    color: $cc-grey--700;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .4s linear;

    @include mq($from: $viewport--lg) {
        display: flex;
    }

    &:hover, &:focus {
        @include mq($from: $viewport--md) {
            opacity: .8;
        }
    }

    .Toggle {
        @include mq($from: $viewport--md) {
            width: 1.75rem;
        }
        &-item {
            @include mq($from: $viewport--md) {
                height: .15rem;
            }
        }
    }

    &.is-visible {
        transition: opacity .4s ease-out .5s;
        @include mq($from: $viewport--md) {
            opacity: 1;
            visibility: visible;
        }
    }
}

.SideNavigation {
    @include position(relative, $z: 3);
    color: $cc-grey--700;
    display: none;

    @include mq($from: $viewport--md) {
        display: block;
    }

    &-body {
        @include position(fixed, u($structure-header-height--mobile), 0, 0, 50vw, 4);
        padding: 2rem 4rem;
        flex-direction: column;
        overflow-y: auto;
        transition: .25s opacity ease-out .4s, .4s transform ease-in-out;

        body:not(.show-sideNav) & {
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .Nav {
        @include transition(color, 400ms, ease-in-out);

        + .Nav {
            border-top: 1px solid;
            margin-top: 2.4rem;
            padding-top: 2.4rem;
        }
    }

    &::before {
        @include transition(transform, 400ms, ease-in-out);
        @include position(fixed, 0, 0, 0, 50vw, 4);
        background-color: $cc-beige--100;
        content: '';

        body:not(.show-sideNav) & {
            transform: translateX(100%);
        }
    }

    &::after {
        @include transition(transform, 400ms, ease-in-out);
        @include position(fixed, 0, 0, 0, 0, 3);
        background: linear-gradient(270deg, rgba($cc-grey--700, .6) 25%, rgba($cc-green--700, .3) 100%);
        content: '';
        transition: opacity .3s ease-in-out, visibility .4s;

        body:not(.show-sideNav) &, {
            opacity: 0;
            visibility: hidden;
        }
    }

    .resize-animation-stopper & * {
        transition: none !important;
    }
}
