/* Blocks */
.Block {
    position: relative;
    z-index: 1;
    padding: 5rem 0;

    @include mq($from: $viewport--md) {
        padding: 10rem 0;
    }
    @include mq($from: $viewport--xl) {
        padding: 15rem 0;
    }

    // - spacing
    + .Block,
    .sprig-component + & {
        padding-top: 0;
    }
    &.Block--noPadding {
        @include mq($from: $viewport--md) {
            padding: 0;
        }
    }
    &.Block--morePadding {
        @include mq($from: $viewport--xl) {
            padding: 20rem 0;
        }
    }

    // - types
    &[data-block-type="ctaImage"] + .Block[data-block-type="ctaImage"] {
        @include mq($from: $viewport--md) {
            margin-top: -10rem;
        }
        @include mq($from: $viewport--xl) {
            margin-top: -15rem;
        }
    }
    &.Block--filters {
        padding-bottom: 2.5rem;
        @include mq($from: $viewport--md) {
            padding-bottom: 5rem;
        }
    }
    &.Block--quickLinks {
        padding: 0;
        .QuickLinks {
            margin-bottom: 2.5rem;
            @include mq($viewport--sm) {
                margin-bottom: 5rem;
            }
        }
    }
    &.Block--static:not(.Block--tank) {
        + .sprig-component > .Block:first-child {
            padding-top: 0;
        }
    }
    &.Block--realisation {
        + .Block--realisation {
            padding-top: 0;
            margin-top: -5rem;
            @include mq($from: $viewport--md) {
                margin-top: -10rem;
            }
            @include mq($from: $viewport--xl) {
                margin-top: -15rem;
            }
        }
        &:not(.Block--lastRealisation) {
            .abs-Realisation-object > * {
                margin-bottom: 3.2rem;
            }
        }
    }

    // - anchor point
    > a[id] {
        display: block;
        position: relative;
        top: -5rem;
        @include mq($from: $viewport--md) {
            top: -10rem;
        }
    }
    &.Block--noPadding {
        > a[id] {
            top: 0;
        }
    }
}
