.Nav--legal {
    --gutter: 3.2rem;
    color: rgba($cc-grey--700, 0.5);

    .Nav-list {

        @include mq($from: $viewport--sm) {
            margin-left: calc(var(--gutter) * -1);

            > .Nav-item {
                padding-left: var(--gutter);
            }
        }

        @include mq($until: $viewport--sm - 1) {
            flex-direction: column;
            line-height: (1 / 16 * 29);

            .Nav-item:first-child {
                margin-top: 2.4rem;
                order: 2;
            }
        }
    }

    .Nav-link {
        color: currentColor;

        &:active,
        &:focus,
        &:hover {
            color: $cc-grey--700;
        }
    }
}
