/* Uni */

.Uni {
    font-size: 2.4rem;
    line-height: 1.5;

    h3, h4, h5 {
        margin-bottom: 4rem;
    }
    p, ul, ol {
        margin-bottom: 4.5rem;
    }

    @include mq($from: $viewport--md) {
        margin: 0 auto;
        width: 80%;
    }
    @include mq($from: $viewport--lg) {
        width: 100%;
        max-width: 80rem;
    }
}
