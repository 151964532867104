.abs-Grid {
    display: grid;
    grid-template-columns: repeat(var(--repeat, 1), 1fr);
    column-gap: 2.4rem;
    row-gap: 3.2rem;

    @include mq($from: $viewport--lg) {
        --repeat: var(--columns, 4);
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        --repeat: 3;
    }

    @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
        --repeat: 2;
    }
}

.abs-Grid {

    + .LoadMore {
        text-align: center;

        .Link[sprig] {
            margin-top: clamp(4rem, 10vw, 16rem);
        }
    }
}

.abs-Grid--withCounter {
    counter-reset: i var(--reset);

    > .abs-Grid-cell:not(.abs-Grid-cell--empty) {
        counter-increment: i var(--increment, 1);
    }
}

.abs-Grid--autoFlow {
    grid-auto-flow: dense;
}

.abs-Grid-cell {
    display: flex;
    flex-direction: column;
}

.abs-Grid-cell--empty {

    @include mq($until: $viewport--md - 1) {
        display: none;
    }
}

.abs-Grid-cell--span {
    grid-column-end: span 2;
}

.abs-Grid-cell--large {
    grid-row-end: span 2;
    grid-column-end: span 3;
}

/*
    Dirty fix #1
    show counter only on cells with an image
 */
.Block--gridAdvanced .abs-Grid--withCounter {
    counter-reset: i var(--reset);

    > .abs-Grid-cell {
        counter-increment: none;
        &.abs-Grid-cell--image {
            counter-increment: i var(--increment, 1);
        }
        &.abs-Grid-cell--text .Project-title::before {
            content: none;
        }
    }
}

/*
    Dirty fix #2
    show counter of images in 'fieldBlocksRealisation'
 */
.RealisationBlocks {
    counter-reset: resetRealisation var(--resetRealisation);

    .abs-Realisation-object > .Project,
    .abs-Realisation-object > .abs-Grid .abs-Grid-cell:not(.abs-Grid-cell--empty) {
        counter-increment: resetRealisation -1;
    }

    .Project-title::before {
        content: counter(resetRealisation, decimal-leading-zero);
    }
}

/*
    Special grid for "_partials/sprig/panden" template
 */
.abs-Grid--special {
    counter-reset: i var(--reset);
}

.abs-Grid-cellWrapper {
    display: grid;
    grid-template-columns: repeat(var(--repeat, 1), 1fr);
    column-gap: 2.4rem;
    row-gap: 3.2rem;

    @include mq($from: $viewport--lg) {
        --repeat: var(--columns, 4);
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        --repeat: 3;
    }

    @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
        --repeat: 2;
    }








    counter-increment: i var(--increment, 1);

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        grid-column: span 3;
    }
    @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
        grid-column: span 2;
    }
    @include mq($from: $viewport--md) {
        grid-template-areas: "image1 info image2";
    }
    @include mq($from: $viewport--lg) {
        grid-column: span 4;
        grid-template-areas: "image1 info image2 .";
    }

    &:nth-child(4n+2) {
        @include mq($from: $viewport--md) {
            grid-template-areas: "image1 image2 info";
        }
        @include mq($from: $viewport--lg) {
            grid-template-areas: ". image1 image2 info";
        }
    }
    &:nth-child(4n+3) {
        @include mq($from: $viewport--md) {
            grid-template-areas: "image1 info image2";
        }
        @include mq($from: $viewport--lg) {
            grid-template-areas: "image1 info . image2";
        }
    }
    &:nth-child(4n+4) {
        @include mq($from: $viewport--md) {
            grid-template-areas: "image1 image2 info";
        }
        @include mq($from: $viewport--lg) {
            grid-template-areas: ". image1 image2 info";
        }
    }

    .abs-Grid-cell--projectImage1 {
        @include mq($from: $viewport--md) {
            grid-area: image1;
        }
    }
    .abs-Grid-cell--projectImage2 {
        @include mq($from: $viewport--md) {
            grid-area: image2;
        }
    }
    .abs-Grid-cell--projectInfo {
        @include mq($from: $viewport--md) {
            grid-area: info;
        }
    }
}
