/* Fields: input */
.#{$form-namespace}Form-field--input,
.#{$form-namespace}Form-field--textarea {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;
}

.#{$form-namespace}Form-field--textarea {
    height: 28rem;
}
