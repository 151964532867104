.CardGrid {
    &-title {
        margin-bottom: clamp(4rem, 5vw, 8rem);
        @include mq($viewport--sm) {
            width: 75%;
        }
    }
    &-items {
        display: grid;
        grid-template-rows: auto;
        grid-gap: 2.5rem;
        margin-bottom: clamp(4rem, 5vw, 8rem);

        &:last-child {
            margin-bottom: 0;
        }

        @include mq($from: $viewport--sm) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($from: 980) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    &-link {
        width: 100%;
        @include mq($viewport--sm) {
            width: 75%;
        }
    }
}
