/* Bar */

.Bars {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1.5rem;
    margin: 0 auto;

    @include mq($from: $viewport--md) {
        width: 75%;
    }
    @include mq($from: $viewport--xl) {
        width: 50%;
    }
}

.Bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $cc-grey--700;
    background-color: $cc-beige--400;
    padding: 3rem;
    text-decoration: none;

    .Wrapper--bg-beige & {
        background-color: $cc-beige--100;
        border-bottom: 1px solid rgba($grey--700, .5);
    }

    &:hover,
    &:focus {
        color: $cc-grey--700;
        background-color: $white;

        .Bar-icon {
            svg {
                transform: rotate(-45deg);
            }
        }
    }

    &-label {

    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $cc-grey--700;

        svg {
            height: 3.2rem;
            width: 3.2rem;
            transform: rotate(45deg);
            fill: currentColor;
            transition: transform .2s ease;
        }
    }
}
