.Editor {

    &--withoutLastMargin {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &--slim {

    }

    &--large {
        h3 {
            font-size: clamp(#{u(32)}, 5vw, #{u($heading-size-2)});
            margin-bottom: u($spacing-unit / 2);
        }
        h4 {
            font-size: u($heading-size-3);
        }
        h5 {
            font-size: u($heading-size-4);
        }
    }

    &--columns {
        @include mq($from: $viewport--md) {
            column-count: 2;
            column-gap: 2.5rem;
        }
        @include mq($from: $viewport--xl) {
            column-gap: 5rem;
        }
    }
}
