.Tart {
    display: block;
    text-decoration: none;
    color: currentColor;

    &:hover,
    &:focus {
        color: currentColor;

        .Tart-title {
            background: $white;
        }
    }

    &-image,
    &-placeholder {
        position: relative;
        background-color: $cc-grey--100;
    }

    &-image {
        img {

        }
    }

    &-placeholder {
        // Ratios
        @each $ratio-name, $ratio-value in $RATIOS {
            @each $antecedent, $consequent in $ratio-value {
                @if (type-of($antecedent) != number) {
                    @error "`#{$antecedent}` needs to be a number.";
                }
                @if (type-of($consequent) != number) {
                    @error "`#{$consequent}` needs to be a number.";
                }
                &[data-ratio="#{$antecedent}:#{$consequent}"] {
                    padding-bottom: ($consequent/$antecedent) * 100%;
                }
            }
        }
    }

    &-body {

    }

    &-title {
        position: relative;
        padding: .75rem 5rem .75rem 1.5rem;
        color: $black;
        background-color: $cc-beige--400;
        transition: .25s background-color ease;

        h3 {
            color: $black;
            font-size: 2.4rem;
            line-height: 1.5;
            margin-bottom: 0;
        }
        .Tart-icon {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                display: block;
                width: 1.6rem;
                height: 1.6rem;
                color: currentColor;
            }
        }

        .Wrapper--bg-beige & {
            background-color: $cc-beige--100;
        }
    }

    &-list {
        font-size: 2.4rem;
        line-height: 1.5;
        &Item {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: .75rem 1.5rem;
            margin-bottom: 2px;
            &:before {
                content: "";
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: var(--page-border-color, #{$cc-beige--400});
                opacity: .5;
            }
        }
        dt {

        }
        dd {

        }
    }
}

.TartSlider {

}
