/* Fields: check */
.#{$form-namespace}Form-field--radio,
.#{$form-namespace}Form-field--checkbox {
    cursor: pointer;
    display: flex;
    user-select: none;
    font-size: 1.6rem;

    a {
        text-decoration-color: transparent;

        &:hover {
            text-decoration-color: currentColor;
        }
    }

    &:not(:first-of-type) {
        margin-top: u(halve($spacing-unit--xs));
    }

    input[type="radio"],
    input[type="checkbox"] {
        @include position(absolute, $top: 0, $left: 0, $z: -1);
        opacity: 0;

        &:checked ~ .#{$form-namespace}Form-indicator {
            //background-color: var(--page-text-color, #{$black});
            //border-color: var(--page-text-color, #{$black});
        }

        // UI state
        /*&:focus ~ .#{$form-namespace}Form-indicator {
            //border-color: $form-element-border-color--focus;
            //box-shadow: if(is-false($form-element-box-shadow--focus), null, $form-element-box-shadow--focus);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23bcbcbc'/%3E%3C/svg%3E");
        }*/
    }

    .#{$form-namespace}Form-indicator {
        position: relative;

        //background-color: var(--site-background-color, #{$black});
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        //border: 1px solid $form-element-border-color;
        border: 1px solid var(--page-text-color, #{$black});
        flex-shrink: 0;
        height: 1.8rem;
        margin-right: u(15);
        width: 1.8rem;
    }

    .FormField-label {
        line-height: 1.5;
        margin-top: u(4);

        &:last-child {
            margin-bottom: u(4);
        }
    }
}

// Radio
.#{$form-namespace}Form-field--radio {

    > .#{$form-namespace}Form-indicator {
        border-radius: 100%;
    }

    input[type="radio"]:checked ~ .#{$form-namespace}Form-indicator {

        &::after {
            background-color: $white;
            border-radius: 100%;
            content: '';
            height: 0.3em;
            width: 0.3em;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// Checkbox
.#{$form-namespace}Form-field--checkbox {

    > .#{$form-namespace}Form-indicator {
        border-radius: u(4);
    }

    input[type="checkbox"]:checked ~ .#{$form-namespace}Form-indicator {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 18 18' style='enable-background:new 0 0 18 18;' xml:space='preserve'%3E%3Cpath d='M7.5,13.5c-0.4,0-0.8-0.2-1-0.5l-3-3c-0.6-0.6-0.6-1.5,0-2.1s1.6-0.6,2.1,0l1.9,1.9l5-5c0.6-0.6,1.5-0.6,2.1,0 s0.6,1.5,0,2.1l-6,6C8.2,13.4,7.9,13.5,7.5,13.5z'/%3E%3C/svg%3E%0A");
    }
}
