/*
    Block Gallery
 */

.BlockGallery {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &-main {
        display: grid;
        grid-template-rows: auto;
        grid-gap: 2.5rem;
        width: 100%;
        @include mq($viewport--sm) {
            grid-template-columns: repeat(2, 1fr);
            width: 75%;
        }
        @include mq($viewport--md) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &-side {
        width: 100%;
        margin-top: 2.5rem;
        @include mq($viewport--md) {
            width: 25%;
            margin-top: 0;
            padding-left: 5rem;
        }
        @include mq($viewport--xl) {
            padding-left: 10rem;
        }
        @include mq(1600) {
            padding-left: 15rem;
        }
    }
}
