.Link {
    display: inline-flex;
    color: var(--page-link-color, #{$link-color});
    text-decoration: none;
    position: relative;
    padding-right: 2rem;
    font-size: 2rem;
    line-height: 1.2;

    @include mq($viewport--md) {
        font-size: 2.8rem;
    }

    &:not(.has-icon) {
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            height: .8rem;
            width: .8rem;
            margin-top: -.2rem;
            background-color: currentColor;
            border-radius: 50%;
            transition: transform .2s ease-out .05s;
        }
    }

    &-label {
        position: relative;
    }

    &-icon {
        position: relative;
        top: 1px;
        width: 1.8rem;
        fill: currentColor;
        margin-left: 1rem;
        transition: .2s transform ease-out;
        will-change: transform;
    }

    &:hover,
    &:focus {
        color: var(--page-link-hover-color, #{$link-color--hover});

        &:before {
            transform: scale(1.5);
        }
    }
}
