/* Logo */
.Logo {
    @include position(relative, $z: 1);
    flex-shrink: 0;
    margin-right: auto;

    svg {
        display: block;
    }
}

// Header
.Logo--header {

    &,
    &:active,
    &:focus,
    &:hover {
        color: currentColor;
        text-decoration: none;
    }

    @include mq($until: $viewport--lg - 1) {
        @include transition(color, 400ms, ease-in-out);
    }

    @include mq($from: $viewport--lg) {
        position: relative;
        top: -1rem;
    }

    .Wrapper--bg-beige & svg,
    .show-nav & svg {
        fill: $cc-grey--700;
    }

    &.hide-text {
        .abs-Logo-main,
        .abs-Logo-baseline {
            display: none;
        }
        .abs-Logo-icon {
            display: block;
        }
    }

    svg {
        height: 5rem;
        fill: $cc-beige--100;
        @include mq($from: $viewport--md) {
            height: 6.5rem;
        }
    }
}

// Footer
.Logo--footer {

    svg {
        height: 5rem;
        fill: $cc-grey--700;
        @include mq($from: $viewport--xl) {
            height: 6.5rem;
        }
    }
}
