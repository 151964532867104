.BlockImage {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > picture,
    &-main {
        width: 100%;
        @include mq($viewport--sm) {
            width: 75%;
        }
    }

    &-side {
        width: 100%;
        margin-top: 2.5rem;
        @include mq($viewport--md) {
            width: 25%;
            margin-top: 0;
            padding-left: 5rem;
        }
        @include mq($viewport--xl) {
            padding-left: 10rem;
        }
        @include mq(1600) {
            padding-left: 15rem;
        }
    }

    &.has-images {
        .BlockImage-object {
            &--desktop {
                @include mq($until: $viewport--sm - 1) {
                    display: none;
                }
            }
            &--mobile {
                @include mq($from: $viewport--sm) {
                    display: none;
                }
            }
        }
    }
}
