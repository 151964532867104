.Card {
    display: block;
    text-decoration: none;
    color: currentColor;

    &:hover,
    &:focus {
        color: currentColor;


        .Card-image {
            img {
                opacity: .9;
                transform: scale(1.1);
            }
        }
        .Card-placeholder {
            opacity: .9;
        }
        .Card-icon {
            svg {
                transform: rotate(-45deg);
            }
        }
    }

    &-image,
    &-placeholder {
        position: relative;
        margin-bottom: 1.5rem;
        background-color: $cc-grey--100;
    }

    &-image {
        img {
            transition: .45s opacity ease, .3s transform ease;
        }
    }

    &-placeholder {
        transition: opacity .6s ease;

        // Ratios
        @each $ratio-name, $ratio-value in $RATIOS {
            @each $antecedent, $consequent in $ratio-value {
                @if (type-of($antecedent) != number) {
                    @error "`#{$antecedent}` needs to be a number.";
                }
                @if (type-of($consequent) != number) {
                    @error "`#{$consequent}` needs to be a number.";
                }
                &[data-ratio="#{$antecedent}:#{$consequent}"] {
                    padding-bottom: ($consequent/$antecedent) * 100%;
                }
            }
        }
    }

    &-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 4.8rem;
        width: 4.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--component-color);
        background-color: var(--component-background-color);

        svg {
            height: 3.2rem;
            width: 3.2rem;
            transform: rotate(45deg);
            fill: currentColor;
            transition: transform .2s ease;
        }
    }

    &-labels {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: -.5rem -.5rem .75rem;
        opacity: .7;
        font-size: 2.4rem;
        &:last-child {
            margin-bottom: -.5rem;
        }
        span {
            display: block;
            margin: .5rem;
        }
    }

    &-title {
        margin-bottom: 0;
    }
}
