.BlockNav {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1rem;
    text-align: center;

    @include mq($from: 980) {
        grid-template-columns: repeat(3, 1fr);
        text-align: left;
    }
}

.BlockNav-item {

    @include mq($until: 979) {

        &:empty {
            display: none;
        }
    }
}

.BlockNav-item--prev {
    .BlockNav-link {
        flex-direction: row-reverse;
    }
    .BlockNav-icon {
        margin-left: 0;
        margin-right: 1.5rem;
        svg {
            transform: rotate(180deg);
        }
    }
}

.BlockNav-item--parent {
    @include mq($from: 980) {
        text-align: center;
    }
}

.BlockNav-item--next {
    @include mq($from: 980) {
        text-align: right;
    }
}

.BlockNav-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: currentColor;
    text-decoration: none;
}

.BlockNav-label {

}

.BlockNav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: currentColor;
    margin-left: 1.5rem;
    position: relative;
    top: 1px;

    svg {
        height: 3.2rem;
        width: 3.2rem;
        //transform: rotate(45deg);
        fill: currentColor;
        transition: transform .2s ease;
    }
}
