.QuickLinks {
    list-style-type: none;
    margin: -.25rem -3rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include mq($viewport--sm) {
        flex-direction: row;
        align-items: center;
    }

    &-item {
        margin: .25rem 3rem;
        &.is-internal {
            .Link {
                &:hover,
                &:focus {
                    .Link-icon {
                        transform: rotate(90deg) translateX(.25rem);
                    }
                }
            }
            .Link-icon {
                transform: rotate(90deg);
            }
        }
    }

    .Link {
        &:hover,
        &:focus {
            .Link-icon {
                transform: translateX(.25rem);
            }
        }
    }
}

