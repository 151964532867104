.Header {
    @include position(fixed, $top: 0, $right: 0, $left: 0, $z: 4);
    height: u($structure-header-height--mobile);
    color: $cc-beige--100;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;

    &.is-fixed {
        position: fixed;
    }

    &.hide-navs {
        .Header-bottom {
            border-top-color: transparent !important;
            transition: border-top-color .25s ease-out;
        }
        .Nav {
            transition: opacity .3s ease-in-out, visibility .3s linear;
            @include mq($from: $viewport--lg) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .show-nav &,
    .show-nav & .Navigation {
        color: $cc-grey--700;
    }

    .Navigation {
        align-self: center;
    }

    .Wrapper--bg-beige & {
        color: $cc-grey--700;
    }

    @include mq($from: $viewport--md) {
        height: u($structure-header-height);
    }
}

.Header-top,
.Header-bottom {
    display: flex;
}

.Header-top {
    color: rgba($cc-beige--400, 0.8);
    font-size: 1.6rem;

    @include mq($until: $viewport--lg - 1) {
        align-items: center;
    }

    @include mq($from: $viewport--lg) {

        + .Header-bottom {
            //border-top: 1px solid rgba($cc-beige--400, 0.5);
            //margin-top: 1.2rem;
            //padding-top: 1.2rem;
            margin-top: .5rem;
        }
    }

    .Wrapper--bg-beige & {
        color: rgba($cc-grey--700, 0.7);

        + .Header-bottom {
            border-top-color: rgba($cc-grey--700, 0.5);
        }
    }
}

.Header-bottom {
    position: relative;
    align-items: flex-start;
    font-size: 2.4rem;
    line-height: 1.25;
    transition: border-top-color .4s ease-out .3s;

    @include mq($from: $viewport--lg) {
        line-height: 1.5;
    }
}
