/* Slider: cube */
.Slider--cube {
    overflow: visible;

    .Slider-item {
        @include dimensions(100%);
        backface-visibility: hidden;
        pointer-events: none;
        transform-origin: 0 0;
        visibility: hidden;
        z-index: 1;

        .Slider-item {
            pointer-events: none;
        }
    }

    &.Slider--rtl .Slider-item {
        transform-origin: 100% 0;
    }

    .Slider-item {

        &.is-active {

            &,
            & .Slider-item.is-active {
                pointer-events: auto;
            }
        }

        &.is-active,
        &.is-next,
        &.is-prev,
        &.is-next + .Slider-item {
            pointer-events: auto;
            visibility: visible;
        }

        &-shadowTop,
        &-shadowBottom,
        &-shadowLeft,
        &-shadowRight {
            backface-visibility: hidden;
            z-index: 0;
        }
    }

    .Slider-cubeShadow {
        @include dimensions(100%);
        @include position(absolute, $bottom: 0, $left: 0, $z: 0);
        opacity: 0.6;

        &:before {
            @include position(absolute, 0, 0, 0, 0);
            background-color: $black;
            content: '';
            filter: blur(50px);
        }
    }
}
