.Navigation {

    &::before {
        @include transition(transform, 400ms, ease-in-out);
        @include position(fixed, 0, 0, 0, 0, 0);
        background-color: $cc-beige--100;
        content: '';
        visibility: hidden;

        body:not(.show-nav) &, {
            transform: translateX(100%);
        }
    }

    @include mq($until: $viewport--lg - 1) {

        .resize-animation-stopper & * {
            transition: none !important;
        }

        &::before {
            visibility: visible;
        }
    }
}

.Navigation-toggle {
    @include transition(color, 400ms, ease-in-out);
    color: currentColor;
    display: block;
}

.Navigation-body {
    display: flex;

    @include mq($from: $viewport--lg) {
        margin-left: u($spacing-unit * -1);

        > .Nav {
            padding-left: u($spacing-unit);
        }
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        padding: u($structure-responsive-gutter--r, $structure-gutter--r);
    }

    @include mq($until: $viewport--md - 1) {
        padding: u($structure-responsive-gutter--r);
    }

    @include mq($until: $viewport--lg - 1) {
        @include position(fixed, u($structure-header-height--mobile), 0, 0, 0, 1);
        @include transition(transform, 400ms, ease-in-out);
        flex-direction: column;
        overflow-y: auto;

        > .Nav {
            @include transition(color, 400ms, ease-in-out);

            + .Nav {
                border-top: 1px solid;
                margin-top: 2.4rem;
                padding-top: 2.4rem;
            }
        }

        body:not(.show-nav) & {
            transform: translateX(100%);
        }
    }
}

.Nav {
    transition: opacity .4s ease-in-out .3s, visibility .4s linear .3s;
}

/* Types */
@import '6-components/navigation/primary';
@import '6-components/navigation/secondary';
@import '6-components/navigation/tertiary';
@import '6-components/navigation/footer';
@import '6-components/navigation/legal';
