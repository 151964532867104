/* Slider: flip */
.Slider--flip {
    overflow: visible;

    .Slider-item {
        backface-visibility: hidden;
        pointer-events: none;
        z-index: 1;

        .Slider-item {
            pointer-events: none;
        }

        &.is-active {

            &,
            & .Slider-item.is-active {
                pointer-events: auto;
            }
        }
    }

    .Slider-item--shadowTop,
    .Slider-item--shadowBottom,
    .Slider-item--shadowLeft,
    .Slider-item--shadowRight {
        backface-visibility: hidden;
        z-index: 0;
    }
}
