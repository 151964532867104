/* Colors: Custom */

$cc-grey--100:                  #eee !default;
$cc-grey--400:                  #ccc !default;
$cc-grey--500:                  #666 !default;
$cc-grey--700:                  #28282A !default;

$cc-beige--100:                 #EEEAE5 !default;
$cc-beige--400:                 #E0D8CD !default;

$cc-red--700:                   #342217 !default;

$cc-green--700:                 #263417 !default;

$cc-blue--700:                  #2F3B41 !default;
