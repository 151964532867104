/* Settings: Fonts */
@font-face {
    font-family: "Degular";
    src:  url('../fonts/degular/Degular-Regular.woff2') format('woff2'),
          url('../fonts/degular/Degular-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

// Default
$font-sans:                             -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-mono:                             SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

// Custom
$font-degular:                          "Degular", sans-serif !default;
