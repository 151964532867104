/* Components: Button */

// Namespace
$button-namespace:                    $namespace !default;

// Typography
$button-font-stack:                   null !default;
$button-font-weight:                  inherit !default;
$button-line-height:                  1.2 !default;
$button-font-size:                    2.4rem !default;
$button-text-align:                   center !default;

// Borders
$button-border-width:                 1 !default;
$button-border-radius:                0 !default;

// States
$button-focus-width:                  1px !default;
$button-focus-color:                  var(--page-link-color) !default;
$button-focus-shadow:                 0 0 0 $button-focus-width $button-focus-color !default;

// Transition
$button-transition:                   (background-color, border-color, box-shadow, color) !default;

// Disabled
$button-disabled-opacity:             60% !default;
