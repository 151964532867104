.Nav--secondary {

    .Header & {

        @include mq($from: $viewport--lg) {
            margin-left: auto;
        }

        @include mq($until: $viewport--lg - 1) {
            display: flex;
            flex-direction: column;
        }

        // List
        .Nav-list {

            @include mq($from: $viewport--lg) {
                flex-direction: row-reverse;
                margin-left: -3.2rem;

                > .Nav-item {
                    padding-left: 3.2rem;
                }
            }

            @include mq($until: $viewport--lg - 1) {
                flex-direction: column;
            }
        }

        // Item
        .Nav-item {

            @include mq($until: $viewport--lg - 1) {

                + .Nav-item {
                    margin-top: 0.6rem;
                }
            }
        }

        // Link
        .Nav-link {
            color: currentColor;

            &:hover,
            &:focus,
            &.is-active {
                color: $cc-grey--100;

                .Wrapper--bg-beige & {
                    color: $cc-grey--700;
                }
            }
        }
    }

    .Footer & {

        .Nav-list {
            flex-direction: column;
        }
    }

    .SideNavigation & {

        display: flex;
        flex-direction: column;

        // List
        .Nav-list {
            flex-direction: column;
        }

        // Item
        .Nav-item {
            + .Nav-item {
                margin-top: 0.6rem;
            }
        }

        // Link
        .Nav-link {
            color: currentColor;

            &:hover,
            &:focus,
            &.is-active {
                color: $cc-grey--100;

                .Wrapper--bg-beige & {
                    color: $cc-grey--700;
                }
            }
        }
    }

    // Link
    .Nav-link {

        &:hover,
        &:focus,
        &.is-active {
            color: currentColor;
        }
    }
}
