.Nav--primary {
    --gutter: 3.2rem;

    .Header & {

        @include mq($until: $viewport--lg - 1) {
            display: flex;
            flex-direction: column;
        }

        // List
        .Nav-list {

            @include mq($from: $viewport--lg) {
                margin-left: calc(var(--gutter) * -1);

                > .Nav-item {
                    padding-left: var(--navGutter);
                }
            }

            @include mq($from: $viewport--xl) {
                --navGutter: 4rem;
            }

            @include mq($from: $viewport--lg, $until: $viewport--xl - 1) {
                --navGutter: 3.2rem;
            }

            @include mq($until: $viewport--lg - 1) {
                flex-direction: column;
            }
        }

        // Item
        .Nav-item {

            @include mq($until: $viewport--lg - 1) {

                + .Nav-item {
                    margin-top: 0.6rem;
                }
            }
        }

        // Link
        .Nav-link {

            @include mq($from: $viewport--lg) {
                display: flex;
                flex-direction: column;
                align-items: center;

                &::after {
                    content: '';
                    margin-top: 0.6rem;
                    @include dimensions(6);
                    background-color: currentColor;
                    border-radius: 100%;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .Footer & {

        .Nav-list {
            flex-direction: column;
        }
    }

    .SideNavigation & {
        display: flex;
        flex-direction: column;

        // List
        .Nav-list {
            flex-direction: column;
        }

        // Item
        .Nav-item {
            + .Nav-item {
                margin-top: 0.6rem;
            }
        }

        // Link
        .Nav-link {

        }
    }

    // Link
    .Nav-link {
        color: currentColor;

        &:hover,
        &:focus,
        &.is-active {
            color: currentColor;

            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
