/*
    Breadcrumbs
 */

.Breadcrumbs {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: -.25rem -.75rem;

    @include mq($from: $viewport--sm) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &:first-child {
        @include mq($from: $viewport--md) {
            top: -3rem;
        }
    }

    + .Headline {
        margin-top: 3rem;

        @include mq($from: $viewport--md) {
            margin-top: 6rem;
        }
        @include mq($from: $viewport--lg) {
            margin-top: 8rem;
        }
    }
}

.Breadcrumbs-item {
    margin: .25rem .75rem;
    display: block;
    color: inherit;
    text-decoration: none;

    @include mq($until: $viewport--md - 1) {
        font-size: 2.4rem;
    }

    + .Breadcrumbs-item {
        &:before {
            content: ">";
            margin-right: 1rem;
        }
    }
}

span.Breadcrumbs-item {
    cursor: default;
}

a.Breadcrumbs-item {
    &:hover, &:focus {

    }
}

