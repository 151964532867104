.#{$button-namespace}ButtonGroup {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > .Button {

    }
}
