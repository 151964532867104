/* Structure */

// Wrapper
.#{$structure-namespace}Wrapper {
    @include grid(100%, auto 1fr auto);
    grid-template-areas: "header" "main" "footer";
    min-height: 100%;

    // Modifiers
    @each $modifier, $colors in $theme-gradients {

        &--bg-#{$modifier} {
            --page-background-color-1: #{nth($colors, 1)};
            --page-background-color-2: #{nth($colors, 2)};
            --page-gradient: linear-gradient(180deg, #{nth($colors, 1)} 0%, #{nth($colors, 2)} 100%);
            @if ($modifier == "beige") {
                --page-text-color: #{$cc-grey--700};
                --page-link-color: #{$cc-grey--700};
                --page-link-hover-color: #{$cc-grey--700};
                --page-border-color: #{$cc-grey--700};

                --page-input-background-color: #{rgba($white, .1)};
                --page-input-border-color: #{$cc-beige--400};
                --page-input-text-color: #{$cc-grey--700};
                --page-input-focus-background-color: #{rgba($white, .3)};;
                --page-input-focus-border-color: #{$cc-beige--400};
                --page-input-focus-text-color: #{$cc-grey--700};

                --page-slider-button-active: #{$cc-grey--700};
                --page-slider-button-disabled: #{$cc-grey--500};

                --component-background-color: #{$cc-grey--700};
                --component-color: #{$cc-grey--400};
            } @else {
                --page-text-color: #{$cc-beige--400};
                --page-link-color: #{$cc-beige--400};
                --page-link-hover-color: #{$white};
                --page-border-color: #{$cc-beige--400};

                --page-input-background-color: #{rgba($white, .1)};
                --page-input-border-color: #{$cc-beige--400};
                --page-input-text-color: #{$white};
                --page-input-focus-background-color: #{$white};
                --page-input-focus-border-color: #{$white};
                --page-input-focus-text-color: #{$cc-grey--700};

                --page-slider-button-active: #{$cc-grey--400};
                --page-slider-button-disabled: #{$cc-grey--100};

                --component-background-color: #{$cc-beige--400};
                --component-color: #{$cc-grey--700};
            }

            background: linear-gradient(180deg, nth($colors, 1) 0%, nth($colors, 2) 100%);
            color: var(--page-text-color);
        }
    }

    // ...
    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        height: 18rem;
        width: 100%;
        background-image: linear-gradient(180deg, $cc-grey--700 0%, rgba($cc-grey--700, 0) 100%);
        pointer-events: none;
        //mix-blend-mode: multiply;
        @include transition(#{opacity, visibility});
    }

    /*&:not(.has-bg):before {
        opacity: 0;
        visibility: hidden;
    }*/

    &.Wrapper--bg-beige {
        color: $cc-grey--700;
        &:before {
            background-image: linear-gradient(180deg, $cc-beige--400 0%, rgba($cc-beige--400, 0) 100%);
        }
    }
}

// Header
.#{$structure-namespace}Header {
    @include position(relative, $z: 3);
    grid-area: header;

    > .#{$container-namespace}Container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include mq($until: $viewport--md - 1) {
        height: if($structure-header-height--mobile != 'auto', u($structure-header-height--mobile), null);
    }
}

// Main
.#{$structure-namespace}Main {
    @include position(relative, $z: 2);
    grid-area: main;

    &-content {
        > .#{$structure-namespace}Container {
            padding-top: u($structure-header-gutter);
            padding-bottom: u($structure-footer-gutter);
        }
        // Spacing header/main
        > .Block:first-child {
            padding-top: 10rem;
            @include mq($from: $viewport--md) {
                padding-top: 12.5rem;
            }
            @include mq($from: $viewport--lg) {
                padding-top: 15rem;
            }
        }
    }
}

// Footer
.#{$structure-namespace}Footer {
    @include position(relative, $z: 1);
    grid-area: footer;
    height: if($structure-footer-height != 'auto', u($structure-footer-height), null);
}
