.Block--realisation {

    &:first-of-type {
        counter-reset: i 1;
    }

    /*& + &.Block,
    .Block--options + &.Block {
        margin-top: var(--spacing, -5rem);
        padding-top: 3.2rem;

        @include mq($from: $viewport--md) {
            --spacing: -10rem;
        }
        @include mq($from: $viewport--xl) {
            --spacing: -15rem;
        }
    }*/
}

.Block.Block--options,
.Block--gridAdvanced .abs-Options {
    padding-bottom: 6rem;
    @include mq($from: $viewport--md) {
        padding-bottom: 8rem;
    }
}

.Block--options + .Block--realisation,
.abs-Options + .abs-Grid {
    //padding-top: 6rem;
    @include mq($from: $viewport--md) {
        //padding-top: 8rem;
    }
}

.abs-Realisation {
    display: grid;
    grid-template-columns: repeat(var(--repeat), 1fr);
    column-gap: 2.4rem;
    row-gap: 3.2rem;

    @include mq($from: $viewport--lg) {
        --repeat: 4;
    }

    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        --repeat: 2;
    }
}

.abs-Realisation-body {
    font-size: 2.4rem;

    @include mq($from: $viewport--md) {
        grid-column: 1 / 2;
    }
}

.abs-Realisation-object {

    @include mq($from: $viewport--md) {
        grid-column: 2 / -1;
    }

    @include mq($until: $viewport--lg - 1) {

        .abs-Grid {
            --repeat: 2;
        }

        .abs-Grid-cell--empty {
            display: none;
        }
    }
}

.abs-Realisation-title {
    font-size: 2.8rem;
    margin-bottom: 4rem;
}

.abs-Realisations {
    margin-top: 6rem;

    @include mq($from: 980) {
        margin: 0 10rem;
    }

    .abs-Teaser {
        @include mq($from: $viewport--xl) {
            grid-template-columns: 1fr 1fr 80rem;
        }
    }

    .Slider-button {
        top: 0;
        margin-top: 0;
        width: 4rem;
        height: 4rem;
        color: var(--page-slider-button-active, #{$cc-beige--400});
        transition: transform .3s ease-out;
        outline: none;

        @include mq($from: 980) {
            top: 22.5vw;
            margin-top: -3rem;
            width: 6rem;
            height: 6rem;
        }
        @include mq($from: 3200) {
            top: 60rem;
        }
        &:after {
            content: none;
        }
        svg {
            width: 3rem;
            height: 3rem;
            fill: currentColor;
            @include mq($from: 980) {
                width: 4rem;
                height: 4rem;
            }
        }
        &--prev {
            left: 0;
            &:hover {
                transform: translateX(-.5rem);
            }
            svg {
                transform: rotate(180deg);
            }
        }
        &--next {
            right: 0;
            &:hover {
                transform: translateX(.5rem);
            }
        }
        &.is-disabled {
            color: var(--page-slider-button-disabled, #{$cc-beige--100});
        }
    }
}

.abs-Units {
    position: relative;

    h2 {
        margin-bottom: 0;
        @include mq($from: $viewport--md) {
            margin-bottom: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-right: 10rem;
        }
    }
    .Slider {
        padding-top: 7.5rem;
        @include mq($from: $viewport--md) {
            padding-top: 14rem;
        }
    }
    .Slider-item {
        overflow: hidden;
    }
    .Slider-button {
        top: 0;
        margin-top: 0;
        color: var(--page-text-color);
        @include mq($from: $viewport--md) {
            top: .75rem;
        }
        &:after {
            content: none;
        }
        svg {
            width: 3rem;
            height: 3rem;
            fill: currentColor;
            @include mq($from: 980) {
                width: 4rem;
                height: 4rem;
            }
        }
        &--prev {
            left: 0;
            right: auto;
            @include mq($from: $viewport--md) {
                right: 5rem;
                left: auto;
            }
            svg {
                transform: rotate(180deg);
            }
        }
        &--next {
            left: 5rem;
            right: auto;
            @include mq($from: $viewport--md) {
                right: 0;
                left: auto;
            }
        }
    }
}

.RealisationBlocks {
    .Block--realisation:first-child {
        padding-top: 0;
    }
}
