.Tiles {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 4rem;

    + .ButtonGroup {
        margin-top: 5rem;

        @include mq($from: $viewport--md) {
            margin-top: 10rem;
        }
        @include mq($from: $viewport--xl) {
            margin-top: 15rem;
        }
    }
}

.Tile {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 2.5rem;
    text-decoration: none;
    color: currentColor;

    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(2, 1fr);
    }

    &:before {
        @include mq($from: $viewport--md) {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            width: 1px;
            margin-left: -.5px;
            background-color: var(--page-border-color, #{$cc-beige--400});
            opacity: .5;
        }
    }

    &-image,
    &-placeholder {
        position: relative;
        background-color: $cc-grey--100;
        transition: opacity .6s ease;

        &:hover,
        &:focus {
            .Tile-icon {
                svg {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &-image {
        display: block;

        &:hover,
        &:focus {
            img {
                transform: scale(1.1);
                opacity: .9;
            }
        }

        picture {
            height: 100%;
            &:before {
                content: "";
            }
        }

        img {
            transition: .45s opacity ease, .3s transform ease;
        }
    }

    &-placeholder {

    }

    &-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 4.8rem;
        width: 4.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--component-color);
        background-color: var(--component-background-color);

        svg {
            height: 3.2rem;
            width: 3.2rem;
            transform: rotate(45deg);
            fill: currentColor;
            transition: transform .15s ease .05s;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include mq($from: $viewport--md) {
            padding: 5rem 5rem 5rem 2.5rem;
        }
        @include mq($from: $viewport--lg) {
            padding: 10rem 10rem 10rem 7.5rem;
        }
    }

    &-title {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &-text {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--alt {
        @include mq($viewport--md) {
            grid-auto-flow: dense;
        }

        .Tile-image {
            @include mq($viewport--md) {
                grid-column: 2;
            }
        }
        .Tile-body {
            @include mq($viewport--md) {
                grid-column: 1;
                padding-left: 5rem;
                padding-right: 2.5rem;
            }
            @include mq($from: $viewport--lg) {
                padding-left: 10rem;
                padding-right: 7.5rem;
            }
        }
    }
}
