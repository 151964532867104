// Options
.abs-Options {
    display: grid;
    gap: 2.4rem;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(var(--repeat, 3), 1fr);
    }

    @include mq($from: $viewport--lg) {
        --repeat: 4;

        &::before {
            content: '';
            grid-column: 1;
        }
    }
}

.abs-Options-item {
    margin-bottom: 0;
}
