.Headline {
    @include mq($from: 980) {
        padding: 0 10rem;
    }
    &-body {
        @include mq($viewport--sm) {
            width: 75%;
        }
        @include mq($from: 980) {
            width: 100%;
        }
        @include mq($viewport--xl) {
            width: 75%;
        }
    }
    &-labels {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: -.5rem -.5rem 0;
        &:last-child {
            margin-bottom: -.5rem;
        }
        span {
            display: block;
            margin: .5rem;
        }
    }
    &-text {
        margin-bottom: clamp(4rem, 5vw, 8rem);
        &:last-child {
            margin-bottom: 0;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-label {
        position: relative;
        padding-left: 2.5rem;
        &:before {
            content: "";
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
            height: 2px;
            width: 1.5rem;
            background-color: var(--page-border-color, #{$cc-beige--400});
            opacity: .5;
        }
    }
    &-links {
        overflow: hidden;
    }

    &.has-label {
        .Headline-text {
            &:not(last-child) {
                margin-bottom: 2rem;
            }
        }
    }
}
