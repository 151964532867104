/* Functions: SVG */
@function svg-url($svg){
    // Add missing namespace
    @if not str-index($svg,xmlns) {
        $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
    }

    // Chunk up string in order to avoid "stack level too deep" error
    $encoded:'';
    $chunkSize: 2048;
    $index: 0;
    $loops: ceil(str-length($svg) / $chunkSize);

    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        '"': "%22",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $chunkSize - 1);

        @each $search, $replace in $map {
            $chunk: str-replace($chunk, $search, $replace);
        }

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $chunkSize;
    }

    @return url("data:image/svg+xml;charset=utf-8,#{$encoded}");
}

// Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @return if($index,
        str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
        $string);
}
