/* Back-to-top button */

.BackToTopWrapper {
    position: fixed;
    bottom: 3.5rem;
    left: 0;
    width: 100%;
    z-index: 100;

    .Container {
        display: flex;
        justify-content: flex-end;
    }
}

.BackToTop {
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    color: $cc-grey--700;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .4s linear;

    @include mq($from: $viewport--md) {
        right: 3rem;
    }

    @include mq($from: $viewport--md) {
        bottom: 3.25rem;
    }

    &:hover, &:focus {
        opacity: .8;
    }

    svg {
        display: block;
        width: 2rem;
        height: 2rem;
        color: currentColor;
        transform: rotate(-90deg);
    }

    &.is-visible {
        transition: opacity .4s ease-out;
        @include mq($from: $viewport--md) {
            opacity: 1;
            visibility: visible;
        }
    }
}
