/* Colors: Theme */

// Lay-out
$primary-color:                     $cc-grey--700 !default;

$text-color:                        $primary-color !default;
$heading-color:                     $primary-color !default;
$bg-color:                          transparentize($primary-color, .95) !default;
$border-color:                      $cc-grey--100 !default;
$image-color:                       $cc-grey--400 !default;

// Modifiers
$theme-modifiers: (
    "white":    $white,
    "grey-100": $cc-grey--100,
    "grey-400": $cc-grey--400,
    "grey-500": $cc-grey--400,
    "grey-700": $cc-grey--700,
    "black":    $black
) !default;

// Modifiers
$theme-gradients: (
    "beige":    [$cc-beige--100, $cc-beige--400],
    "blue":     [$cc-grey--700, $cc-blue--700],
    "green":    [$cc-grey--700, $cc-green--700],
    "red":      [$cc-grey--700, $cc-red--700]
) !default;
