
/* Object: Button */
.#{$button-namespace}Button {
    @include transition($button-transition, 150ms, ease-in-out);
    -webkit-appearance: none; // Correct the inability to style clickable types in iOS and Safari.
    background-color: transparent;
    border-radius: if($button-border-radius > 0, px($button-border-radius), 0);
    border: px($button-border-width) solid transparent;
    color: var(--page-link-color, #{$link-color});
    cursor: pointer;
    display: inline-flex;
    font-family: $button-font-stack;
    font-size: if($button-font-size == $font-size, null, u($button-font-size));
    font-weight: $button-font-weight;
    line-height: $button-line-height;
    padding: 0 2rem 0 0;
    position: relative;
    text-align: $button-text-align;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;

    @include mq($viewport--md) {
        font-size: 2.8rem;
    }

    &:not(.has-icon) {
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            height: .8rem;
            width: .8rem;
            margin-top: -.2rem;
            background-color: currentColor;
            border-radius: 50%;
        }
    }

    &-label {
        position: relative;
    }

    &-icon {
        position: relative;
        top: 1px;
        width: 1.8rem;
        fill: currentColor;
        margin-left: 1rem;
    }

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:hover,
    &:focus {
        color: var(--page-link-hover-color, #{$link-color--hover});
    }

    // States
    &:focus-visible,
    &.is-focus {
        outline: 0;
        box-shadow: $button-focus-shadow;
    }

    &:disabled,
    &.is-disabled {
        box-shadow: none;
        cursor: default;
        opacity: strip-unit($button-disabled-opacity / 100);
        pointer-events: none;
    }
}
