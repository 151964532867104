.CookieGroups {
    display: flex;
    justify-content: flex-end;

    @include mq($from: 980) {
        padding: 0 10rem;
    }

    * + & {
        margin-top: 2.5rem;
        @include mq($from: $viewport--md) {
            margin-top: 5rem;
        }
    }
}

.CookieGroups-wrap {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 2rem;
    @include mq($from: $viewport--md) {
        width: 80%;
    }
    @include mq($from: $viewport--xl) {
        gap: 4rem;
        width: 100%;
        max-width: 80rem;
    }
}

.CookieGroup {
    table {
        border-color: var(--page-border-color);
        border-spacing: 0;
    }
    thead {
        background-color: var(--page-border-color);
        border-bottom: none;
    }
    th {
        font-size: 1.8rem;
        color: var(--component-color);
    }
    td {
        font-size: 1.6rem;
    }
    tr {
        border-top: none;
    }
}
